/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.col-number {
  width: 60px;
  height: 60px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  margin: 0 auto 28px; }
  .col-number-1 {
    background-image: url("../img/icons/number-1-inact.svg"); }
  .col-number-2 {
    background-image: url("../img/icons/number-2-inact.svg"); }
  .col-number-3 {
    background-image: url("../img/icons/number-3-inact.svg"); }

.col-title, .col-lead {
  text-transform: uppercase; }

.col-content {
  position: relative;
  background-color: #343641;
  border: 2px solid #454754;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-clip: border-box;
  padding: 30px 30px 70px; }

.col-title {
  margin-bottom: 31px; }

.col-lead {
  border-bottom: 1px solid #7C7F8D;
  padding-bottom: 19px;
  margin-bottom: 20px; }

.col-desc {
  font-size: 17px;
  line-height: 1.64706;
  margin-bottom: 36px; }

.col-button {
  pointer-events: none;
  display: flex;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 30px;
  max-width: 174px;
  width: 100%;
  margin: 0 auto; }
  .col-button:hover {
    transform: translateX(-50%) scale(0.9); }

.col-skip {
  display: inherit;
  font-size: 10px;
  font-weight: 700;
  color: #DCDDE3;
  line-height: 1.6;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: center;
  margin-top: 21px; }

.col-item {
  max-width: 330px;
  flex-basis: 330px;
  color: #7C7F8D;
  padding: 0 15px; }
  .col-item.active {
    color: #FFFFFF; }
    .col-item.active .col-number-1 {
      background-image: url("../img/icons/number-1-act.svg"); }
    .col-item.active .col-number-2 {
      background-image: url("../img/icons/number-2-act.svg"); }
    .col-item.active .col-number-3 {
      background-image: url("../img/icons/number-3-act.svg"); }
    .col-item.active .col-content {
      border-color: transparent;
      background-color: transparent;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #8048FF 20%, #238AFF 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #3D3F4B inset; }
    .col-item.active .col-lead {
      border-color: #606371; }
    .col-item.active .col-button {
      pointer-events: auto;
      background-image: linear-gradient(90deg, #6639CD 40%, #387FCF 100%); }
    .col-item.active .col-skip {
      color: #DCDDE3; }
  .col-item.done .col-number-1, .col-item.skipped .col-number-1 {
    background-image: url("../img/icons/number-1-act.svg"); }
  .col-item.done .col-number-2, .col-item.skipped .col-number-2 {
    background-image: url("../img/icons/number-2-act.svg"); }
  .col-item.done .col-number-3, .col-item.skipped .col-number-3 {
    background-image: url("../img/icons/number-3-act.svg"); }
  .col-item.done .col-button, .col-item.skipped .col-button {
    max-width: 249px; }

@media (max-width: 767px) {
  .col-item:not(:last-child) {
    margin-bottom: 40px; } }
