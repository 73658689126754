/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.box, .box-footer {
  max-width: 630px; }

.box {
  margin: 0 auto 60px; }
  .box-title {
    color: #131417;
    margin-bottom: 21px; }
  .box-subtitle {
    font-weight: 400;
    color: #131417;
    margin-bottom: 22px; }
  .box-footer {
    padding-top: 30px;
    margin: 0 auto; }
  .box ul li {
    color: #131417;
    padding-right: 30px;
    margin-bottom: 21px; }

@media (max-width: 991px) {
  .box {
    padding: 20px;
    margin: 0 auto 40px; }
    .box ul li {
      padding-right: 0; } }

@media (max-width: 991px) {
  .box {
    padding: 20px 15px;
    margin: 0 auto 40px; } }
