/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.navbar {
  background-color: #25272E;
  box-shadow: 0 20px 30px rgba(18, 18, 18, 0.25);
  padding: 1.25rem 1rem; }
  .navbar .container, .navbar-top .container {
    max-width: 1325px; }
  .navbar .btn {
    min-width: 175px;
    margin-left: 20px; }
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0; }
  .navbar-top {
    background-color: #1E1F25; }
    .navbar-top .row {
      align-items: center;
      height: 40px; }
    .navbar-top ul {
      margin-bottom: 0; }
    .navbar-top a {
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 700;
      color: #DCDDE3;
      text-transform: uppercase;
      text-decoration: underline; }
      .navbar-top a:hover {
        text-decoration: underline; }
      .navbar-top a.dropdown-toggle::after {
        display: none; }
    .navbar-top .dropdown-menu {
      background: linear-gradient(180deg, #E7E8EC 33.33%, #B0B2BD 100%);
      border-color: transparent; }
    .navbar-top .dropdown-item {
      color: #131417;
      text-decoration: none;
      padding: 8px 24px; }
      .navbar-top .dropdown-item:hover {
        text-decoration: none;
        background-color: #FFFFFF; }
    .navbar-top-left {
      display: flex;
      align-self: center;
      padding-left: 15px; }
      .navbar-top-left-arrow {
        margin-right: 8px; }
      .navbar-top-left li {
        margin-right: 25px; }
        .navbar-top-left li a {
          min-height: 16px; }
    .navbar-top-right {
      padding-left: 0; }
      .navbar-top-right-icon {
        margin-right: 8px; }
      .navbar-top-right-arrow {
        margin-left: 8px; }

.navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDMwIDMwIj48cGF0aCBzdHJva2U9InJnYmEoMjU1LCAyNTUsIDI1NSwgMC41KSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTQgN2gyMk00IDE1aDIyTTQgMjNoMjIiLz48L3N2Zz4="); }

@media (max-width: 767px) {
  .navbar {
    padding: 0.75rem 1rem; }
    .navbar .btn {
      min-width: 150px;
      margin-left: 15px; }
    .navbar-brand img {
      width: 150px;
      height: auto; } }

@media (max-width: 575px) {
  .navbar-collapse .navbar-nav {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 3px; }
    .navbar-collapse .navbar-nav li:not(:last-child) {
      margin-bottom: 15px; } }
