/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.form-group {
  margin-bottom: 0; }

.form-label {
  font-size: 14px;
  color: #BABCC6;
  line-height: 1.42857;
  margin-bottom: 5px; }

.form-control {
  height: 40px;
  font-size: 18px;
  color: #BABCC6;
  line-height: 18px;
  background-color: #2D2F38;
  border-color: #1E1F25;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-clip: border-box;
  box-shadow: none;
  padding: 11px 16px;
  margin-bottom: 15px; }
  .form-control:hover, .form-control:active, .form-control:focus, .form-control.active {
    color: #BABCC6;
    background-color: #2D2F38;
    border-color: #1E1F25; }

.chk-group {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #E7E8EC;
  line-height: 1.42857;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 29px;
  padding-right: 15px; }
  .chk-group input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    visibility: hidden; }
    .chk-group input:checked ~ .checkmark {
      border-color: transparent;
      box-shadow: none; }
    .chk-group input:checked ~ .checkmark:after {
      display: block;
      background-image: url("../img/icons/check-white.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto; }
  .chk-group a {
    display: contents;
    font-size: 14px;
    color: #E7E8EC;
    line-height: 1.42857;
    text-decoration: underline;
    margin-left: 5px; }
  .chk-group .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #868997;
    background-origin: border-box;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #8048FF 20%, #238AFF 100%);
    background-clip: content-box, border-box;
    box-shadow: 1px 1000px 1px #343641 inset;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-clip: border-box; }
    .chk-group .checkmark:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      background-clip: border-box;
      background-color: transparent; }
