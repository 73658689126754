/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
html.page-loaded .tr {
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; }
  html.page-loaded .tr-600 {
    -moz-transition: all 600ms ease-in-out;
    -o-transition: all 600ms ease-in-out;
    -webkit-transition: all 600ms ease-in-out;
    transition: all 600ms ease-in-out; }
