/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.btn {
  cursor: pointer;
  display: inline-flex;
  display: -webkit-inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  color: #FFFFFF;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-clip: border-box;
  -moz-transition: transform 300ms ease;
  -o-transition: transform 300ms ease;
  -webkit-transition: transform 300ms ease;
  transition: transform 300ms ease;
  padding: 0 15px; }
  .btn:hover {
    color: #FFFFFF;
    transform: scale(0.9); }
  .btn:focus {
    box-shadow: none; }
  .btn-full {
    width: 100%; }
  .btn-wide {
    max-width: 300px;
    width: 100%; }
  .btn-big {
    height: 68px; }
  .btn-big-alt {
    height: 52px; }
  .btn-center {
    margin: 0 auto; }
  .btn-shadow {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3); }
  .btn-gradient {
    background-image: linear-gradient(90deg, #6639CD 20%, #387FCF 100%); }
    .btn-gradient:hover, .btn-gradient:focus {
      background-image: linear-gradient(90deg, #6639CD 0%, #6639CD 100%); }
  .btn-darkgradient {
    background-image: linear-gradient(90deg, #343641 20%, #3D3F4B 80%); }
  .btn-yellowgradient {
    color: #25272E;
    background-image: linear-gradient(90deg, #95D373 8.23%, #4AD4B3 92.17%); }
  .btn-orangegradient {
    color: #25272E;
    background-image: linear-gradient(90deg, #FF9E75 8.23%, #FFD98E 92.17%); }
  .btn-ghost {
    border: 1px solid transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #8048FF 20%, #238AFF 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 1px 1000px 1px #25272E inset; }
    .btn-ghost:hover {
      box-shadow: none; }
  .btn-lefticon img {
    -ms-transform: translateY(1px);
    -webkit-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px);
    margin-right: 4px; }
  .btn-icon-right {
    margin-left: 16px; }

@media (max-width: 767px) {
  .btn-big {
    height: 45px; }
  .btn-big-alt {
    height: 42px; } }
