/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.page-login {
  position: relative;
  background: linear-gradient(180deg, #131417 0%, #25272E 100%);
  padding-top: 67px;
  padding-bottom: 106px; }
  .page-login h1 {
    margin-bottom: 94px; }
  .page-login .btn-gradient.btn-big-alt {
    display: flex;
    max-width: 240px;
    width: 100%; }
  .page-login-left, .page-login-right {
    margin-bottom: 98px; }
  .page-login-left {
    align-self: center; }
    .page-login-left .inner {
      border-top: 1px solid #606371;
      border-bottom: 1px solid #606371;
      padding-top: 53px;
      padding-bottom: 52px;
      margin-right: 57px; }
    .page-login-left h2 {
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.33333333;
      margin-bottom: 19px; }
    .page-login-left h3 {
      font-weight: 400;
      color: #BABCC6;
      line-height: 1.4;
      margin-bottom: 18px; }
    .page-login-left p {
      font-size: 17px;
      color: #E7E8EC;
      line-height: 1.64706; }
      .page-login-left p:not(:last-child) {
        margin-bottom: 29px; }
    .page-login-left .btn {
      margin-bottom: 0; }
  .page-login-right form {
    background: linear-gradient(90deg, #343641 20%, #3D3F4B 80%);
    border: 2px solid #606371;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background-clip: border-box;
    padding: 61px 30px 72px; }
    .page-login-right form h2 {
      font-size: 28px;
      color: #FFFFFF;
      line-height: 1.2857142857; }
    .page-login-right form p {
      font-size: 17px;
      color: #BABCC6;
      line-height: 1.64706;
      margin-bottom: 58px; }
    .page-login-right form .form-group {
      margin-bottom: 27px; }
    .page-login-right form .form-control {
      height: 52px; }
    .page-login-right form .btn-gradient {
      margin: 60px auto 32px; }
    .page-login-right form a {
      display: inherit;
      font-size: 14px;
      color: #DCDDE3;
      line-height: 1.1428571429;
      text-transform: uppercase; }
      .page-login-right form a:hover {
        text-decoration: none; }
  .page-login:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 22.5vw;
    height: 509px;
    background-image: url("../img/bg/bg2.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain; }

@media (max-width: 1199px) {
  .page-login h1 {
    margin-bottom: 50px; }
  .page-login-left, .page-login-right {
    margin-bottom: 60px; }
  .page-login-left .inner {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-right: 0; }
  .page-login:before {
    width: 35vw; } }

@media (max-width: 767px) {
  .page-login h1 {
    margin-bottom: 30px; }
  .page-login-left {
    align-self: unset;
    margin-bottom: 30px; }
    .page-login-left .inner {
      padding-top: 30px;
      padding-bottom: 30px;
      margin-right: 0; }
    .page-login-left .btn-gradient {
      margin: 0 auto; }
  .page-login-right form {
    padding: 30px 30px; }
    .page-login-right form p {
      margin-bottom: 30px; }
    .page-login-right form .btn-gradient {
      margin: 30px auto; }
  .page-login:before {
    width: 70vw; } }
