/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.error {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(62, 65, 83, 0.88);
  z-index: 1050; }
  .error.sys {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center; }
  .error-content {
    padding: 0 15px; }
    .error-content h2 {
      margin-bottom: 35px; }
    .error-content ul {
      margin-bottom: 0; }
      .error-content ul li:last-child {
        margin-bottom: 0; }
    .error-content .btn-gradient {
      display: flex;
      max-width: 150px;
      width: 100%;
      margin: 0 auto; }
      .error-content .btn-gradient.btn-mt26 {
        margin-top: 26px; }
  .error-link {
    display: inherit;
    font-size: 10px;
    font-weight: 700;
    color: #DCDDE3;
    line-height: 1.6;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    margin-top: 26px; }
    .error-link:hover, .error-link:active, .error-link:focus, .error-link.active {
      color: #DCDDE3; }
