/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
* {
  box-sizing: border-box; }

body {
  font-family: "Noto Sans", sans-serif; }

.doublenav {
  min-height: calc(100vh - 130px); }

.singlenav {
  min-height: calc(100vh - 90px); }

strong {
  font-weight: 700; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.link-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center; }

.link-icon-right {
  margin-left: 8px; }

.img-resp {
  max-width: 100%;
  height: auto; }

.img-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: border-box; }

ul, ol {
  list-style-type: none; }
  ul.ul, ol.ul {
    position: relative;
    list-style-position: outside;
    list-style-type: none;
    padding-left: 30px; }
    ul.ul li, ol.ul li {
      position: relative;
      display: list-item;
      font-size: 14px;
      color: #131417;
      line-height: 1.42857; }
      ul.ul li:before, ol.ul li:before {
        content: '';
        position: absolute;
        top: 5px;
        left: -26px;
        width: 10px;
        height: 10px;
        background: linear-gradient(90deg, #8048FF 20%, #238AFF 100%);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-clip: border-box; }

.bg-dark {
  background-color: #343641; }

.bg-test {
  background-color: #888888; }

.box {
  background: linear-gradient(180deg, #E7E8EC 33.33%, #B0B2BD 100%);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-clip: border-box;
  padding: 30px; }
  .box-title {
    font-size: 14px;
    font-weight: 700;
    color: #131417;
    line-height: 1.42857; }

@media (max-width: 767px) {
  .doublenav {
    min-height: calc(100vh - 104px); } }
