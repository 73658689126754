/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
body h1, body .h1, body h2, body .h2, body h3, body .h3, body h4, body .h4, body h5, body .h5, body h6, body .h6 {
  font-family: "Noto Sans", sans-serif;
  font-weight: 700; }

.hero {
  font-size: 56px;
  line-height: 1.14286; }

body h1, body .h1 {
  font-size: 40px;
  line-height: 1.2;
  color: #FFFFFF; }

body h2, body .h2 {
  font-size: 30px; }

body h3, body .h3 {
  font-size: 24px; }

body h4, body .h4 {
  font-size: 20px; }

body h5, body .h5, body .lead {
  font-size: 20px;
  line-height: 1.4; }

body h6, body .h6 {
  font-size: 16px;
  line-height: 1.5; }

body a, body p, body span, body ul, body ol {
  font-size: 16px;
  line-height: 1.25; }

@media (max-width: 991px) {
  .hero {
    font-size: 40px; } }

@media (max-width: 767px) {
  body h1, body .h1 {
    font-size: 30px; }
  body h2, body .h2 {
    font-size: 25px; }
  body h3, body .h3 {
    font-size: 20px; }
  body h4, body .h4 {
    font-size: 18px; }
  body h5, body .h5 {
    font-size: 18px; } }

@media (max-width: 575px) {
  .hero {
    font-size: 30px; } }
