/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.page-main .btn-gradient,
.page-login .btn-gradient {
  display: flex;
  margin-bottom: 40px; }
  .page-main .btn-gradient.short,
  .page-login .btn-gradient.short {
    max-width: 204px;
    height: 40px; }

.page-main {
  position: relative;
  background: linear-gradient(180deg, #25272E 0%, #131417 100%);
  padding-top: 30px;
  padding-bottom: 0; }
  .page-main h1 {
    margin-bottom: 13px; }
  .page-main .lead {
    color: #FFFFFF;
    margin-bottom: 40px; }
  .page-main .txt {
    font-size: 14px;
    color: #FFFFFF;
    line-height: 1.42857;
    margin-bottom: 21px; }
  .page-main:before {
    content: '';
    position: absolute;
    top: -127px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/bg/bg.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: auto; }

@media (max-width: 991px) {
  .page-main {
    padding-top: 30px;
    padding-bottom: 30px; }
    .page-main .col-9 {
      max-width: 100%;
      flex-basis: 100%; } }
