/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.page-test {
  padding-top: 30px;
  padding-bottom: 0; }
  .page-test .container {
    position: relative; }
  .page-test .information {
    cursor: pointer;
    /*
        position: absolute;

        top: 0;
        left: 15px;*/ }
  .page-test .container-test-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .page-test .container-test-info img {
      margin-right: 15px; }
    .page-test .container-test-info p {
      font-family: 'Noto Sans', sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #343641;
      line-height: 28px;
      text-transform: uppercase;
      margin-left: auto;
      margin-bottom: 0; }

@media (max-width: 990px) {
  .page-test .information {
    top: -60px; } }
