/*$backdrop: rgba(0, 0, 0, .8);*/
/*Project Colors: */
/**
*
* Testing
*
**/
.modal .close {
  position: absolute;
  top: 29px;
  right: 30px;
  text-shadow: none;
  opacity: 1;
  padding: 0;
  margin: 0; }
  .modal .close span {
    font-size: 28px;
    color: #E7E8EC; }

.modal-dialog {
  max-width: 630px; }

.modal-header {
  flex-wrap: wrap;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-color: #606371;
  padding: 0; }
  .modal-header .h5 {
    max-width: 100%;
    flex-basis: 100%;
    color: #BABCC6;
    margin-bottom: 20px; }
  .modal-header-mb {
    margin-bottom: 20px; }

.modal-body {
  padding: 0; }
  .modal-body * {
    font-size: 17px;
    color: #E7E8EC;
    line-height: 1.64706;
    margin-bottom: 27px; }

.modal-content {
  border: 2px solid #606371;
  background-color: #343641;
  box-shadow: 0 30px 60px 0 rgba(18, 18, 18, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-clip: border-box;
  padding: 29px 30px 24px; }
  .modal-content form {
    max-width: 470px;
    margin: 0 auto; }
    .modal-content form p {
      margin-bottom: 16px; }
    .modal-content form .chk-group {
      margin-bottom: 12px; }
    .modal-content form .btn-gradient {
      display: flex;
      max-width: 300px;
      width: 100%;
      margin: 29px auto 0; }

.modal-subtitle {
  max-width: 100%;
  flex-basis: 100%;
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #7C7F8D;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 30px; }

.modal-title {
  margin-bottom: 19px; }
  .modal-title.h1 {
    color: #E7E8EC; }

.modal-lead {
  display: flex;
  align-items: center;
  min-height: 69px;
  border-bottom: 1px solid #606371;
  padding: 19px 0; }
  .modal-lead-title {
    color: #E7E8EC;
    text-transform: uppercase;
    margin-bottom: 0; }
  .modal-lead-text {
    font-size: 17px;
    color: #9093A0;
    line-height: 1.64706;
    margin-left: 13px;
    margin-bottom: 0; }
    .modal-lead-text-gray {
      color: #E7E8EC;
      padding: 0 26px;
      margin-left: 0; }

.modal-link {
  font-size: 10px;
  font-weight: 700;
  color: #DCDDE3;
  line-height: 1.6;
  text-transform: uppercase;
  text-decoration: underline; }
  .modal-link:hover, .modal-link:active, .modal-link:focus, .modal-link.active {
    color: #DCDDE3; }

.modal-footer {
  border-top: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 21px 0 0 0; }
  .modal-footer-column {
    flex-direction: column; }
    .modal-footer-column .btn {
      max-width: 260px;
      width: 100%;
      order: 2; }
      .modal-footer-column .btn-gradient {
        order: 1; }
  .modal-footer-center {
    justify-content: center; }
  .modal-footer.justify-content-between {
    padding: 49px 0 21px 0; }

.modal#test-popup.modal .close {
  right: auto;
  left: 30px; }

.modal#test-popup .modal-dialog {
  max-width: 410px;
  margin-left: 10px; }

.modal#test-popup .modal-content {
  box-shadow: none; }

.modal#test-popup .modal-subtitle {
  padding-left: 38px; }

.modal#test-popup .modal-footer-column {
  border-top: 1px solid #606371; }
  .modal#test-popup .modal-footer-column .btn {
    color: #7C7F8D; }

@media (max-width: 767px) {
  .modal-lead {
    flex-wrap: wrap; } }
